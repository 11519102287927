/* eslint-env jquery */

(function($) {
  /*
   **********************
   *** Scripts persos ***
   **********************
   */

  $(document).ready(function() {
    /*
    -- Bouton Menu Reponsive --
    */
    $('.btnresp').click(function() {
      $('.menu').toggleClass('open');
    });

    $('.menu > .menu-item-has-children').click(function() {
      $(this)
        .find('.sub-menu')
        .toggleClass('active');
    });

    if ($(window).scrollTop() > 50) {
      $('header').addClass('scrolling');
    }

    /*
    -- Accordéons gamme --
    */

    if ($('.gammecaracteristique').length) {
      $('.caracteristique:first-child').addClass('open');
      $('.caracteristique:first-child .caracteristique--texte').slideDown();

      $('.caracteristique--titre').click(function() {
        $('.caracteristique--texte').slideUp();
        $(this)
          .next()
          .slideDown();
        $('.caracteristique').removeClass('open');
        $(this)
          .parent()
          .addClass('open');

        // Rescroll sur les onglets, avec décalage pour le header fixe *sigh*
        $('html, body').animate({ scrollTop: $('.gammecaracteristique').offset().top - 150 }, 500);
      });
    }

    /*
    -- Onglets personnalisation --
    */
    if ($('.gammepersonnalisation').length) {
      $('.onglet:first-child').addClass('open');
      $('.resume:first-child').slideDown();

      $('.onglet').click(function() {
        var $index = $(this).index() + 1;
        $('.resume').slideUp();
        $('.resume:nth-child(' + $index + ')').slideDown();
        $('.onglet').removeClass('open');
        $(this).addClass('open');
      });
    }

    /*
    -- Slider Actualités dans pages (owlCarousel) --
    */
    $('.slideactus').owlCarousel({
      animateOut: 'fadeOut',
      dots: false,
      lazyLoad: true,
      loop: true,
      margin: 65,
      nav: true,
      navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 2,
        },
      },
    });
  });

  /*
  -- Header fixe qui change au scroll --
  */

  $(window).scroll(function() {
    if ($(window).scrollTop() > 50) {
      $('header').addClass('scrolling');
    } else {
      $('header').removeClass('scrolling');
    }
  });
})(jQuery);
